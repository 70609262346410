<template>
  <div>
    <div v-if="loadingFormData" class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-1">{{ $t('CATALOG_LOADING_LABEL', {catalog: $t('ROUTE')})  | capitalize }}</strong>
    </div>
    <b-card class="mb-2" bg-variant="dark" text-variant="light" v-else :title="$t('FORMS_GENERAL_DATA_TITLE') | capitalize">
      <b-card-text>
        <pre v-if="debug">{{ form }}</pre>
        <b-form @submit.prevent="save">

          <div class="w-100">
            <!-- company -->
            <b-form-group
                class="d-inline-block w-50"
                :label="$t('FORMS_MULTI_SELECT_LABEL', {catalog: $t('COMPANY')}) | capitalize"
                label-for="company-input"
                :state="state('company')">
              <company-single-selector
                  id="company-input"
                  @input="loadTurns()"
                  undefined-means="FORMS_SELECT_ONE"
                  :state="state('company')"
                  :errors="errors('company')"
                  v-model="$v.form.company.$model">
              </company-single-selector>
            </b-form-group>
            <!-- company -->

            <!-- turn -->
            <b-form-group
                class="d-inline-block w-50 pl-1"
                :label="$t('FORMS_MULTI_SELECT_LABEL', {catalog: $t('TURN')}) | capitalize"
                label-for="company-input"
                :state="state('turn')">
              <turn-single-selector
                  v-if="form.company"
                  id="company-input"
                  undefined-means="FORMS_SELECT_ONE"
                  :company-id="companyId"
                  :state="state('turn')"
                  :errors="errors('turn')"
                  v-model="$v.form.turn.$model">
              </turn-single-selector>
              <b-select v-else class="w-100"></b-select>
            </b-form-group>
            <!-- turn -->
          </div>

          <div class="w-100">
            <!-- name text input -->
            <b-form-group
                id="name-form-group"
                style="vertical-align: top !important;"
                class="d-inline-block w-50"
                :label="$t('ROUTE_NAME_LABEL') | capitalize"
                label-for="name-input"
                :state="state('name')">
              <b-form-input class="w-100" id="name-input" type="text"
                            v-model="$v.form.name.$model"
                            :state="state('name')"
                            trim></b-form-input>

              <b-form-invalid-feedback id="name-input-feedback">
                <div v-for="error in errors('name')" :key="error.error">
                  {{
                    $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('ROUTE_NAME_LABEL')},})  | capitalize
                  }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- name text input -->

            <!-- description text input -->
            <b-form-group
                id="description-form-group"
                class="d-inline-block w-50 pl-1"
                :label="$t('ROUTE_DESCRIPTION_LABEL') | capitalize"
                label-for="description-input"
                :state="state('description')">
              <b-form-textarea class="w-100" id="description-input" type="text"
                               v-model="$v.form.description.$model"
                               :state="state('description')"
                               trim></b-form-textarea>

              <b-form-invalid-feedback id="description-input-feedback">
                <div v-for="error in errors('description')" :key="error.error">
                  {{
                    $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('ROUTE_DESCRIPTION_LABEL')},})  | capitalize
                  }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- description text input -->
          </div>

          <!-- accounting input -->
          <b-form-group
              id="accounting-form-group"
              class="w-100 d-inline-block"
              :label="$t('ROUTE_ACCOUNTING_LABEL') | capitalize"
              label-for="accounting-input"
              :state="state('accounting')">
            <b-checkbox v-model="$v.form.accounting.$model" class="w-100 mr-1" id="accounting-input"></b-checkbox>

            <b-form-invalid-feedback id="accounting-input-feedback">
              <div v-for="error in errors('accounting')" :key="error.error">
                {{
                  $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('ROUTE_ACCOUNTING_LABEL')},})  | capitalize
                }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- accounting input -->

          <!-- map input -->
          <single-file-upload @change="(value) => {$v.form.map.$model = value[0];}" selection-mode="single"
                              :label="$t('ROUTE_MAP_LABEL') | capitalize"
                              max-m-b="50"
                              :current-file="form.map"
                              :valid-extensions="['jpg','png','bmp']"
                              name="map"
                              :id="this.form.uploadDirectory"
                              :image="true"></single-file-upload>
          <!-- map input -->

          <b-button class="mt-2" type="submit" :disabled="saving" variant="primary">
            <b-icon v-if="saving" icon="circle-fill" animation="throb" class="mr-2"></b-icon>
            <span v-if="saving">{{ $t('FORMS_SAVING_LABEL')  | capitalize }}</span>
            <span v-else>{{ $t('FORMS_SAVE_LABEL')  | capitalize }}</span>
          </b-button>
        </b-form>
      </b-card-text>
    </b-card>

    <!-- events -->
    <b-card bg-variant="dark" text-variant="light" :title="$t('ROUTE_EVENTS_DETAILS') | capitalize" class="mb-2">
      <b-card-text>
        <b-alert :show="showWarningAboutFromPasses" variant="warning">
          {{$t('ERROR_EVENTS_MUST_HAVE_FROM') | capitalize}}
        </b-alert>

        <b-alert :show="showWarningAboutToPasses" variant="warning">
          {{$t('ERROR_EVENTS_MUST_HAVE_TO') | capitalize}}
        </b-alert>

        <b-button @click="addEvent">{{$t('FORMS_DETAIL_ADD') | capitalize}}</b-button>
        <table class="w-100">
          <thead>
          <tr>
            <th>{{$t('ROUTE_EVENTS_NAME_LABEL') | capitalize}}</th>
            <th>{{$t('ROUTE_EVENTS_TIME_LABEL') | capitalize}}</th>
            <th>{{$t('ROUTE_EVENTS_COLOR_LABEL') | capitalize}}</th>
            <th>{{$t('ROUTE_EVENTS_DIRECTION_LABEL') | capitalize}}</th>
          </tr>
          </thead>
          <tbody>
          <tr :key="index" v-for="(v, index) in $v.form.events.$each.$iter">
            <td class="event-name">
              <b-form-group
                  id="route-events-name-form-group"
                  label-for="route-events-name-input"
                  :state="state2(v, 'name')">
                <b-form-input  id="route-events-name-input" type="text"
                               v-model="v.name.$model"
                               :state="state2(v, 'name')"
                               trim></b-form-input>

                <b-form-invalid-feedback id="route-events-name-input-feedback">
                  <div v-for="error in errors2(v, 'name')" :key="error.error">
                    {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('ROUTE_EVENTS_NAME_LABEL')},})  | capitalize }}
                  </div>
                </b-form-invalid-feedback>
              </b-form-group>
            </td>
            <td class="event-time">
              <b-form-group
                  id="route-events-time-form-group"
                  label-for="route-events-time-input"
                  :state="state2(v, 'time')">
                <b-form-timepicker  id="route-events-time-input" type="text"
                               v-model="v.time.$model"
                               :state="state2(v, 'time')"
                               trim></b-form-timepicker>

                <b-form-invalid-feedback id="route-events-time-input-feedback">
                  <div v-for="error in errors2(v, 'time')" :key="error.error">
                    {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('ROUTE_EVENTS_TIME_LABEL')},})  | capitalize }}
                  </div>
                </b-form-invalid-feedback>
              </b-form-group>
            </td>
            <td>
              <b-form-group
                  id="route-events-color-input-form-group"
                  label-for="route-events-route-events-color-input"
                  :state="state2(v, 'color')">
                <b-input id="route-events-color-input"
                          v-model="v.color.$model"
                         type="color"
                          :state="state2(v, 'color')">
                </b-input>
                <b-form-invalid-feedback id="route-events-color-input-feedback">
                  <div v-for="error in errors2(v, 'color')" :key="error.error">
                    {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('ROUTE_EVENTS_COLOR_LABEL')},})  | capitalize }}
                  </div>
                </b-form-invalid-feedback>
              </b-form-group>
            </td>
            <td>
              <!-- travelDirection -->
              <b-form-group
                  v-model="v.travelDirection.$model"
                  id="travel-direction-form-group"
                  label-for="travel-direction-input"
                  :state="state2(v, 'travelDirection')">
                <b-select v-model="v.travelDirection.$model">
                  <b-select-option value="TRAVEL_TO">{{ $t('TRAVEL_PROGRAMMING_TRAVEL_TO') | capitalize }}</b-select-option>
                  <b-select-option value="TRAVEL_FROM">{{ $t('TRAVEL_PROGRAMMING_TRAVEL_FROM')  | capitalize }}
                  </b-select-option>
                </b-select>

                <b-form-invalid-feedback id="travel-direction-input-feedback">
                  <div v-for="error in errors2(v, 'travelDirection')" :key="error.error">
                    {{
                      $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('TRAVEL_DIRECTION_LABEL')},})  | capitalize
                    }}
                  </div>
                </b-form-invalid-feedback>
              </b-form-group>
              <!-- travelDirection -->
            </td>
            <td style="vertical-align: top">
              <b-button @click="removeEvent(index)" variant="danger">
                <b-icon-x></b-icon-x>
              </b-button>
            </td>
          </tr>
          </tbody>
        </table>
      </b-card-text>
    </b-card>
    <!-- events -->

  </div>
</template>

<script>
import {required, minLength, maxLength} from "vuelidate/lib/validators";
import {Form, ProcessWithLoadingAndMessage} from "@/mixins";
import Route from "@/routes";
import Constants from "@/constants";
import SingleFileUpload from "@/components/SingleFileUpload";
import CompanySingleSelector from "@/companies/CompanySingleSelector"
import TurnSingleSelector from "@/turns/TurnSingleSelector";
import eventBus from "@/events";
import {v4} from "uuid";

export default {
  name: "RouteForm",
  components: {TurnSingleSelector, SingleFileUpload, CompanySingleSelector},
  mixins: [Form, ProcessWithLoadingAndMessage],
  methods: {
    addEvent() {
      this.form.events.push({name: '', time: '', color: '', travelDirection: 0})
    },
    removeEvent(idx) {
      this.form.events.splice(idx, 1);
    },
    loadTurns() {
      eventBus.$emit('ROUTE_FORM_COMPANY_CHANGE', this.companyId);
    },
    async doSave(id, data) {
      return id ? await Route.update(id, data) : await Route.create(data);
    },
    getData() {
      this.loadingFormData = true;
      Route.findById(this.id)
          .then(resp => {
            this.form.uploadDirectory = resp.data.uploadDirectory;
            this.form.name = resp.data.name;
            this.form.description = resp.data.description;
            this.form.accounting = resp.data.accounting;
            this.form.map = resp.data.map;
            this.form.company = resp.data._embedded.company._links.self.href.replace('{?projection}', '');
            this.form.turn = resp.data._embedded.turn._links.self.href.replace('{?projection}', '');
            this.originals.name = resp.data.name;
            this.form.events = resp.data.events;
          })
          .catch(e => {
            if (Constants.DEBUG) {
              console.error(e);
            }
            this.sendError('%loadingFormDataErrorMessage%', e);
          })
          .finally(() => {
            this.loadingFormData = false;
          });
    }
  },
  data() {
    return {
      loadingFormData: false,
      originals: {
        name: null
      },
      form: {
        uploadDirectory: v4(),
        name: null,
        description: null,
        accounting: null,
        map: null,
        company: undefined,
        turn: undefined,
        events: []
      },
      editPage: 'ROUTE_EDIT_TITLE'
    }
  },
  computed: {
    companyId() {
      if (this.form.company) {
        const split = this.form.company.split('/');
        return split[split.length - 1];
      } else {
        return undefined;
      }
    },
    showWarningAboutToPasses() {
      return this.form.events.map(x => x.travelDirection).indexOf('TRAVEL_TO') === -1;
    },
    showWarningAboutFromPasses() {
      return this.form.events.map(x => x.travelDirection).indexOf('TRAVEL_FROM') === -1;
    }
  },
  validations: {
    form: {
      name: {
        isUnique(value) {
          // standalone validator ideally should not assume a field is required
          if (!value || value === '') return true

          if (this.originals.name && this.originals.name === value) return true;

          // simulate async call, fail for all logins with even length
          return Route.nameExists(value);
        }, required, minLength: minLength(4), maxLength: maxLength(100)
      },
      description: {maxLength: maxLength(500)},
      accounting: {},
      map: {},
      company: {required},
      turn: {required},
      events: {
        required, minLength: minLength(1), $each: {
          name: {required, minLength: minLength(4), maxLength: maxLength(300)},
          time: {required},
          color: {required},
          travelDirection: {required}
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
